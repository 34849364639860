import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { connect, useDispatch, useSelector } from 'react-redux';
import { replace } from 'connected-react-router';
import { useHistory } from 'react-router-dom';
import Copyright from '../../components/Copyright';
import { changePassword as changePasswordExternalAction } from '../../store/actions/user.actions';
import * as Selectors from '../../store/selectors';
import { initialise } from '../../store/actions/project.actions';
import { getPasswordResetCodeFromUri } from '../../helpers/project-helpers';

const validationSchema = yup.object({
  currentPassword: yup
    .string('Please enter your current password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  newPassword: yup
    .string('Please enter your new password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('New password is required'),
  confirmPassword: yup
    .string('Please confirm your new password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('New password is required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

const theme = createTheme();

const styles = {
  errorText: { color: 'red', fontSize: 'small' },
};

function ChangePassword({ changePassword, resetLocationTo }) {
  const dispatch = useDispatch();
  const history = useHistory();

  // Determine if we're still fetching the users project
  const projectIsLoading = useSelector(Selectors.isProjectLoading);

  // Get the project from the store
  const project = useSelector(Selectors.getProject);

  const apiError = useSelector(Selectors.getUserError);

  // Get the intended redirect URL which (if valid and is approved) will be where the user ends up after a successful auth
  const code = getPasswordResetCodeFromUri(history.location.search);

  useEffect(() => {
    dispatch(initialise({ queryParams: history.location.search }));
  }, [dispatch, history.location.search]);

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: (values) => {
      changePassword(
        {
          ...values,
          code,
        },
        project.projectId
      );
    },
  });

  // TODO: Change this to something peeeerty
  if (projectIsLoading) {
    return 'Loading....';
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Change Password
          </Typography>
          <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="currentPassword"
              label="Current Password"
              type="password"
              id="currentPassword"
              autoComplete="current-password"
              value={formik.values.currentPassword}
              onChange={formik.handleChange}
              error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
              helperText={formik.touched.currentPassword && formik.errors.currentPassword}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type="password"
              id="newPassword"
              autoComplete="new-password"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
              helperText={formik.touched.newPassword && formik.errors.newPassword}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              autoComplete="new-password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            />
            <Typography style={styles.errorText}>{apiError}</Typography>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Change Password
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

const mapDispatchToProps = (dispatch) => ({
  changePassword: (values) => dispatch(changePasswordExternalAction(values)),
  resetLocationTo: (route) => dispatch(replace(route)),
});

/**
 * Note: This component is intentionally using `connect` to simplify integration with Formik. tsk tsk
 */
export default connect(null, mapDispatchToProps)(ChangePassword);
