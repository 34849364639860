import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import appReducer from './slices/app.slice';
import userReducer from './slices/user.slice';
import projectReducer from './slices/projects.slice';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    user: userReducer,
    project: projectReducer,
  });

export default rootReducer;
