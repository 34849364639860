import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { connect, useDispatch, useSelector } from 'react-redux';
import { replace } from 'connected-react-router';
import { useHistory } from 'react-router-dom';
import Copyright from '../../components/Copyright';
import { loginUser, loginWithProvider } from '../../store/actions/user.actions';
import * as Selectors from '../../store/selectors';
import { routes } from '../../config';
import { initialise } from '../../store/actions/project.actions';
import { ERROR_CODES } from '../../config/strings';

const validationSchema = yup.object({
  username: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

const theme = createTheme();

const styles = {
  errorText: { color: 'red', fontSize: 'small' },
};

function SignIn({ login, resetLocationTo }) {
  const dispatch = useDispatch();
  const history = useHistory();

  // Determine if we're still fetching the users project
  const projectIsLoading = useSelector(Selectors.isProjectLoading);

  // Get the project from the store
  const project = useSelector(Selectors.getProject);

  const authError = useSelector(Selectors.getAuthError);

  useEffect(() => {
    dispatch(initialise({ queryParams: history.location.search }));
  }, [dispatch, history.location.search]);

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => login(values, project.projectId),
  });

  const handleSignupLink = () => {
    resetLocationTo(`${routes.SIGNUP}${history.location.search}`);
  };

  const handleForgottenPasswordLink = () => {
    resetLocationTo(routes.RESET_PASSWORD_REQUEST);
  };

  const handleProviderLogin = (provider) => {
    dispatch(loginWithProvider({ provider }));
  };

  const translateError = (error) => {
    const { code, message } = error || {};
    // User is already verified
    // if (code === ERROR_CODES.USER_VERIFIED) {
    //   return (
    //     <Typography style={styles.errorText}>
    //       Account is already verified. Click{' '}
    //       <Link href="" variant="body2" onClick={handleLogin}>
    //         here to sigin.
    //       </Link>
    //     </Typography>
    //   );
    // }

    return <Typography style={styles.errorText}>{message}</Typography>;
  };

  // TODO: Change this to something peeeerty
  if (projectIsLoading) {
    return 'Loading....';
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email Address"
              name="username"
              autoComplete="email"
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            {translateError(authError)}
            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Sign In
            </Button>
            <Button
              type="button"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => handleProviderLogin('facebook')}
            >
              Sign In with Facebook
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2" onClick={handleForgottenPasswordLink}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="" variant="body2" onClick={handleSignupLink}>
                  Don't have an account? Sign Up
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

const mapDispatchToProps = (dispatch) => ({
  login: (values, projectId, redirectURI) => dispatch(loginUser(values, projectId, redirectURI)),
  resetLocationTo: (route) => dispatch(replace(route)),
});

/**
 * Note: This component is intentionally using `connect` to simplify integration with Formik. tsk tsk
 */
export default connect(null, mapDispatchToProps)(SignIn);
