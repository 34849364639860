/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  projectId: null,
  isValid: false,
  error: null,
  uriParams: {},
};

const startLoading = (state) => {
  state.isLoading = true;
  state.error = null;
};

const project = createSlice({
  name: 'project',
  initialState,
  reducers: {
    fetchProjectStart: startLoading,
    fetchProjectEnd(state) {
      state.isLoading = false;
    },
    clearError(state) {
      state.authError = null;
    },
    setProjectSuccess(state, { payload }) {
      const newState = {
        ...state,
        ...payload,
        isValid: true,
        isLoading: false,
        error: null,
      };

      return newState;
    },
    fetchProjectFailed(state) {
      state.isLoading = false;
    },
    // These are the params we will include with the authorization request
    setURIParams(state, { payload }) {
      state.uriParams = payload;
    },
  },
});

export const { fetchProjectStart, fetchProjectEnd, clearError, setProjectSuccess, fetchProjectFailed, setURIParams } =
  project.actions;

export default project.reducer;
