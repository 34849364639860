import api from '../../services/api-service';
import { userToken } from '../selectors';
import { logoutSuccess } from '../slices/user.slice';

export default (store) => (next) => (action) => {
  api.session.interceptors.request.use(
    (config) => {
      const state = store.getState();
      const token = userToken(state);

      if (token) {
        config.headers.common.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );
  api.session.interceptors.response.use(
    (response) => response,
    async (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const { response: { status } = {} } = error;
      if (status === 401) {
        await store.dispatch(logoutSuccess());
      }

      return Promise.reject(error);
    }
  );

  return next(action);
};
