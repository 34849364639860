export const userToken = (state) => state.user.token;
export const getAuthError = (state) => state.user.authError;
export const getVerificationError = (state) => state.user.verificationCodeError;
export const getSignupError = (state) => state.user.signupError;
export const getIdentityEmail = (state) => state.user.email;
export const getUser = (state) => state.user;
export const isProjectLoading = (state) => state.project.isLoading;
export const getProject = (state) => state.project;
export const getApplicationError = (state) => state.app.error;
export const getUserError = (state) => state.user.error;
export const getUserPasswordResetCodeIsLoading = (state) => state.user.passwordResetCodeIsLoading;
export const getUserPasswordResetCodeError = (state) => state.user.passwordResetCodeError;
export const getURIParams = (state) => state.project.uriParams;
