import './App.css';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../config';
import Signin from '../Signin/Signin';
import SignUp from '../Signup';
import Verify from '../Verify';
import Error from '../Error';
import ChangePassword from '../ChangePassword';
import ResetPassword from '../ResetPassword';
import ResetPasswordRequest from '../ResetPasswordRequest';

const App = () => (
  <Switch>
    <Route exact path={routes.SIGNIN} component={Signin} />
    <Route exact path={routes.VERIFY} component={Verify} />
    <Route exact path={routes.SIGNUP} component={SignUp} />
    <Route exact path={routes.RESET_PASSWORD_REQUEST} component={ResetPasswordRequest} />
    <Route exact path={routes.RESET_PASSWORD} component={ResetPassword} />
    <Route exact path={routes.CHANGE_PASSWORD} component={ChangePassword} />
    <Route exact path={routes.ERROR} component={Error} />
    {/* <Route exact path={routes.NOT_FOUND} render={<div>Not Found</div>} /> */}
  </Switch>
);

export default App;
