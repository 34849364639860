import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { replace } from 'connected-react-router';
import Copyright from '../../components/Copyright';
import { verifyUser, resendVerificationCode } from '../../store/actions/user.actions';
import { userVerificationStart } from '../../store/slices/user.slice';
import * as Selectors from '../../store/selectors';
import { routes } from '../../config';
import { ERROR_CODES } from '../../config/strings';

const validationSchema = yup.object({
  code: yup
    .string('Enter your verification code')
    .min(6, 'Verification should be of minimum 6 characters length')
    .required('Verification code is required'),
});

const theme = createTheme();

const styles = {
  errorText: { color: 'red', fontSize: 'small' },
};

function Verify({ navigate, user, verify, requestVerificationCode, resetLocationTo }) {
  const apiError = useSelector(Selectors.getVerificationError);
  const history = useHistory();

  const { email } = user;

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema,
    onSubmit: (values) => verify({ email, verificationCode: values.code }),
  });

  const handleResend = (e) => {
    e.preventDefault();
    requestVerificationCode({ email });
  };

  const handleLogin = () => {
    navigate(routes.SIGNIN);
  };

  const translateError = (error) => {
    const { code, message } = error || {};
    // User is already verified
    if (code === ERROR_CODES.USER_VERIFIED) {
      return (
        <Typography style={styles.errorText}>
          Account is already verified. Click{' '}
          <Link href="" variant="body2" onClick={handleLogin}>
            here to sigin.
          </Link>
        </Typography>
      );
    }

    return <Typography style={styles.errorText}>{message}</Typography>;
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <VerifiedUserIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Verify
          </Typography>

          <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1, minWidth: 300 }}>
            <Typography variant="body2" sx={{ mt: 3 }}>
              A verification code has been sent to {email}. Please verify your account.
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="code"
              label="Verification Code"
              name="code"
              autoComplete="off"
              value={formik.values.code}
              onChange={formik.handleChange}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
              autoFocus
            />

            {translateError(apiError)}
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Verify
            </Button>
            <Grid container>
              <Grid item>
                <Link href="" variant="body2" onClick={handleResend}>
                  Resend verification code
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => ({
  user: Selectors.getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  verify: (values) => dispatch(verifyUser(values)),
  requestVerificationCode: (values) => dispatch(resendVerificationCode(values)),
  resetLocationTo: (route) => dispatch(replace(route)),
  clearAPIErrors: () => dispatch(userVerificationStart()),
  navigate: (route) => dispatch(replace(route)),
});

/**
 * Note: This component is intentionally using `connect` to simplify integration with Formik.
 */
export default connect(mapStateToProps, mapDispatchToProps)(Verify);
