import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { useHistory } from 'react-router-dom';
import Copyright from '../../components/Copyright';
import * as Selectors from '../../store/selectors';
import { errorConfig } from '../../config';
import { getURLParamsFromURI } from '../../helpers/uri-helpers';

const theme = createTheme();

const styles = {
  errorText: { color: 'red' },
};

function Error({ resetLocationTo, appError }) {
  const history = useHistory();
  const [error, setError] = useState(null);
  useEffect(() => {
    // Get the error from the query params
    const urlParams = getURLParamsFromURI(history.location.search);
    const {
      error: errorCode,
      error_description: errorDescription,
      error_type: errorType,
      error_param: errorParam,
    } = urlParams;

    if (errorDescription) {
      setError({ errorType: errorCode, description: errorDescription });
    } else if (errorCode) {
      // If it's a validation error we need to be a bit more dynamic
      if (errorCode === errorConfig.errorCodes.VALIDATION_ERROR) {
        if (errorType === errorConfig.errorTypes.PARAMETER_INVALID) {
          setError({ errorType: errorCode, description: `Parameter '${errorParam}' contains an invalid value.` });
        } else if (errorType === errorConfig.errorTypes.PARAMETER_REQUIRED) {
          setError({ errorType: errorCode, description: `Parameter '${errorParam}' was missing and is required.` });
        }
      } else {
        // Convert it from our mapped config
        const errorCfg = errorConfig.errorCodeMap[errorCode] || errorConfig.errorCodeMap.server_error;
        setError({ errorType: errorCode, description: errorCfg });
      }
    } else {
      setError({ description: errorConfig.errorCodeMap.server_error.message });
    }
  }, [history.location.search]);
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'red' }}>
            <ErrorIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Error
          </Typography>
          <Box sx={{ mt: 4 }}>
            <Typography style={styles.errorText}>{error?.description || ''}</Typography>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => ({ appError: Selectors.getApplicationError(state) });

const mapDispatchToProps = (dispatch) => ({
  resetLocationTo: (route) => dispatch(replace(route)),
});

/**
 * Note: This component is intentionally using `connect` to simplify integration with Formik.
 */
export default connect(mapStateToProps, mapDispatchToProps)(Error);
