import { ERRORS, INTERNAL_ERROR } from '../config/strings';

export const translateError = (error, status = null) => {
  if (error) {
    if (status && status === 400) {
      return error;
    }
    const { message } = error;
    return ERRORS[message] || INTERNAL_ERROR;
  }
  return null;
};
