/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
};

const project = createSlice({
  name: 'app',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    setError(state, { payload }) {
      state.error = payload;
    },
  },
});

export const { clearError, setError } = project.actions;

export default project.reducer;
