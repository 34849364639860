import qs from 'query-string';
import { identityDomain } from '../config';

export const getProjectIdFromUri = () => {
  const [projectId] = window.location.hostname.split('.');
  return projectId;
};

export const getProjectIdentityUri = () => `${getProjectIdFromUri()}.${identityDomain}`;

export const getPasswordResetCodeFromUri = (path) => {
  const { px_rc: resetCode } = qs.parse(path);
  return resetCode;
};
