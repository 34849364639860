import ErrorConfig from './error-config';

const { version } = require('../../package.json');

export const environment = process.env.NODE_ENV || 'development';

export const apiURL = process.env.REACT_APP_API_URL || 'https://kaius.jmg.local:3010';
export const apiBaseDomain = process.env.REACT_APP_API_BASE_DOMAIN || 'jmg.local:3010';
export const identityDomain = process.env.REACT_APP_IDENTITY_DOMAIN || 'id.kaius.io';

export const correlationHeaderName = process.env.REACT_APP_CORRELATION_HEADER_NAME || 'x-correlation-id';

export const enforceAuthentication = true;

export const routes = {
  ERROR: '/error',
  SIGNIN: '/',
  VERIFY: '/verify',
  SIGNUP: '/signup',
  LOGOUT: '/logout',
  RESET_PASSWORD_REQUEST: '/reset-password-request',
  RESET_PASSWORD: '/reset-password',
  CHANGE_PASSWORD: '/change-password',
};

export const appEnvironment = process.env.REACT_APP_ENVIRONMENT;
export const appVersion = process.env.REACT_APP_APPLICATION_VERSION || version;

export const errorConfig = ErrorConfig;
