/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  authError: null,
  token: undefined,
  logoutRedirectURL: undefined,
  requestedUrl: undefined,
  isLoading: false,
  error: null,
  signupError: null,
  verificationCodeSent: null,
  verificationCodeError: null,
  passwordResetCodeError: null,
  passwordResetCodeIsLoading: false,
};

const startLoading = (state) => {
  state.isLoading = true;
  state.error = false;
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchUserStart: startLoading,
    clearLoginFailure(state) {
      state.authError = null;
    },
    loginFailure(state, { payload }) {
      state.authError = payload;
    },
    signupFailure(state, { payload }) {
      state.signupError = payload;
    },
    fetchUserFailure(state, { payload }) {
      state.error = payload;
      state.isLoading = false;
    },
    setUserSuccess(state, { payload }) {
      const newState = {
        ...state,
        ...payload,
        isLoading: false,
        authError: null,
      };

      return newState;
    },
    setRequestedURL(state, { payload }) {
      state.requestedUrl = payload;
    },
    logoutSuccess(state, { payload }) {
      return {
        ...initialState,
        logoutRedirectURL: payload,
      };
    },
    clearLogoutRedirectURL(state) {
      state.logoutRedirectURL = null;
    },
    resetUserLoadingState(state) {
      state.isLoading = false;
    },
    clearSignupFailure(state) {
      state.signupError = null;
    },
    resendUserVerificationCodeSuccess(state) {
      state.verificationCodeSent = true;
    },
    // Start of resending a verification code
    resendUserVerificationCodeStart(state) {
      state.verificationCodeSent = null;
      state.verificationCodeError = null;
    },
    // Requesting a verification code failed
    resendUserVerificationCodeFailed(state, { payload }) {
      state.verificationCodeError = payload;
    },
    // Verification failed
    userVerificationFailed(state, { payload }) {
      state.verificationCodeError = payload;
    },
    userVerificationStart(state) {
      state.verificationCodeError = null;
    },
    // Start of requesting a password reset code
    userPasswordResetCodeStart(state) {
      state.passwordResetCodeError = null;
      state.passwordResetCodeIsLoading = true;
    },
    // Requesting a password reset code failed
    userPasswordResetCodeFailed(state, { payload }) {
      state.passwordResetCodeError = payload;
      state.passwordResetCodeIsLoading = false;
    },
    userPasswordResetCodeSuccess(state) {
      state.passwordResetCodeError = null;
      state.passwordResetCodeIsLoading = false;
    },
    setError(state, { payload }) {
      state.error = payload;
    },
  },
});

export const {
  loginFailure,
  clearLoginFailure,
  fetchUserStart,
  fetchUserFailure,
  setUserSuccess,
  setRequestedURL,
  logoutSuccess,
  clearLogoutRedirectURL,
  resetUserLoadingState,
  signupFailure,
  clearSignupFailure,
  resendUserVerificationCodeSuccess,
  resendUserVerificationCodeFailed,
  resendUserVerificationCodeStart,
  userVerificationFailed,
  userVerificationStart,
  userPasswordResetCodeSuccess,
  userPasswordResetCodeFailed,
  userPasswordResetCodeStart,
  setError,
} = user.actions;

export default user.reducer;
