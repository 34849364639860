/* eslint-disable symbol-description */
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { getProjectIdFromUri } from '../helpers/project-helpers';

import { apiBaseDomain, correlationHeaderName } from '../config';

const singleton = Symbol();
const singletonEnforcer = Symbol();

const generateCorrelationId = () => uuid();

export const getAPIHostURL = () => {
  // Now attempt to identify the project we're on
  const projectId = getProjectIdFromUri();
  return `https://${projectId}.${apiBaseDomain}`;
};

class ApiService {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot construct singleton');
    }

    this.session = axios.create({
      baseURL: getAPIHostURL(),
      headers: {
        [correlationHeaderName]: generateCorrelationId(),
        Pragma: 'no-cache',
      },
      params: {},
    });
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new ApiService(singletonEnforcer);
    }

    return this[singleton];
  }

  get(...params) {
    return this.session.get(...params);
  }

  post(...params) {
    return this.session.post(...params);
  }

  patch(...params) {
    return this.session.patch(...params);
  }

  // eslint-disable-next-line class-methods-use-this
  batch(requests) {
    return axios.all(requests);
  }
}

export default ApiService.instance;
