export default {};

export const INTERNAL_ERROR = `Something unexpected happened. Please try again.`;
export const ERRORS = {
  'Network Error': INTERNAL_ERROR,
};

export const ERROR_CODES = {
  USER_VERIFIED: 'USER_VERIFIED',
  USER_UNVERIFIED: 'USER_UNVERIFIED',
};
