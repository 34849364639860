import api, { getAPIHostURL } from './api-service';
import { getProjectIdFromUri } from '../helpers/project-helpers';

const buildAuthRedirectURI = (
  provider,
  state,
  clientId,
  redirectURI,
  responseType,
  codeChallenge,
  codeChallengeMethod
) => {
  const redirectURL = new URL(`${getAPIHostURL()}/oauth/v2.1/authorize`);
  redirectURL.searchParams.append('provider', provider);
  redirectURL.searchParams.append('state', state);
  redirectURL.searchParams.append('client_id', clientId);
  redirectURL.searchParams.append('redirect_uri', redirectURI);
  redirectURL.searchParams.append('response_type', responseType);
  redirectURL.searchParams.append('code_challenge', codeChallenge);
  redirectURL.searchParams.append('code_challenge_method', codeChallengeMethod);
  return redirectURL.href;
};

export const signInWithPassword = async (payload) => api.post('/auth/signin-with-password-redirect', payload);
export const signInWithProvider = async ({ provider, state, redirectURI, codeChallenge, codeChallengeMethod }) => {
  try {
    window.location.href = buildAuthRedirectURI(
      provider,
      state,
      getProjectIdFromUri(),
      redirectURI,
      'code',
      codeChallenge,
      codeChallengeMethod
    );
  } catch (error) {
    console.error('Looks like there was an issue logging out. Please try again or contact support.', error);
  }
};
export const logout = async () => api.get('logout');
export const resendVerificationCode = async (email) => api.get(`/auth/resend-verification-code`, { params: { email } });
export const requestPasswordReset = async (email, projectId, redirectURI) =>
  api.get(`/auth/password-reset-code`, { params: { email, projectId, redirect_uri: redirectURI } });
export const changePasswordWithCode = async (currentPassword, newPassword, confirmPassword, code) =>
  api.post(`/users/change-password-with-code`, {
    currentPassword,
    newPassword,
    confirmPassword,
    code,
  });
export const resetPassword = async (newPassword, confirmPassword, code) =>
  api.post(`/auth/reset-password`, {
    newPassword,
    confirmPassword,
    code,
  });
export const verifyUser = async (email, verificationCode) =>
  api.post(`/auth/verify`, {
    email,
    verificationCode,
  });

export default {
  signInWithPassword,
  signInWithProvider,
  logout,
  requestPasswordReset,
  resendVerificationCode,
  changePasswordWithCode,
  resetPassword,
  verifyUser,
};
