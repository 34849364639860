import { replace, push } from 'connected-react-router';
import * as ProjectService from '../../services/project.service';
import { routes } from '../../config';
import { translateError } from '../../helpers/error-helpers';
import { getProjectIdFromUri } from '../../helpers/project-helpers';
import { getURLParamsFromURI } from '../../helpers/uri-helpers';

const { fetchProjectStart, fetchProjectEnd, setProjectSuccess, setURIParams } = require('../slices/projects.slice');
const { setError } = require('../slices/app.slice');

export const fetchProject = (projectId) => async (dispatch) => {
  try {
    dispatch(fetchProjectStart());

    const response = await ProjectService.getProject(projectId);

    if (response.status === 204) {
      dispatch(setProjectSuccess({ projectId }));
    } else {
      // More than 1 project was found. This isn't ideal..
      console.log('More than 1 project found');
      dispatch(replace(routes.ERROR));
      dispatch(setError(`Invalid project '${projectId}'.`));
    }
  } catch (error) {
    const { message } = error;
    const { response: { data: { message: apiError } = {}, status } = {} } = error;

    if (status === 404) {
      dispatch(setError(`Invalid project '${projectId}'.`));
      dispatch(replace(routes.ERROR));
    } else {
      console.error('Looks like there was an issue fetching the project. Please try again or contact support.', error);
      dispatch(setError(translateError(apiError || message, status)));
      dispatch(push(routes.ERROR));
    }
  } finally {
    dispatch(fetchProjectEnd());
  }
};

export const initialise =
  ({ queryParams = null } = {}) =>
  async (dispatch) => {
    // Now attempt to identify the project we're on
    const projectId = getProjectIdFromUri();
    dispatch(fetchProject(projectId));

    // Get the intended redirect URL which (if valid and is approved) will be where the user ends up after a successful auth
    // Here we are checking if there are query params so we don't override when transitioning between screens
    if (queryParams) {
      const uriParams = getURLParamsFromURI(queryParams);
      dispatch(setURIParams(uriParams));
    }
  };
