export const errorCodeMap = {
  unauthorized_client: {
    message: 'Failed to authenticate with provider.',
  },
  server_error: {
    message: 'Something unexpected happened. Please try again.',
  },
};

export const errorCodes = {
  VALIDATION_ERROR: 'invalid_request',
  INVALID_CLIENT: 'invalid_client',
  INVALID_SCOPE: 'invalid_scope',
  INVALID_GRANT: 'invalid_grant',
  UNAUTHORIZED_CLIENT: 'unauthorized_client',
  UNSUPPORTED_GRANT_TYPE: 'unsupported_grant_type',
  SERVER_ERROR: 'server_error',
};

export const errorTypes = {
  PARAMETER_REQUIRED: 'parameter_required',
  PARAMETER_INVALID: 'parameter_invalid',
};

export default { errorCodes, errorCodeMap, errorTypes };
