import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { connect, useSelector, useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';
import { useHistory } from 'react-router-dom';
import Copyright from '../../components/Copyright';
import { signupUser } from '../../store/actions/user.actions';
import { clearSignupFailure } from '../../store/slices/user.slice';
import * as Selectors from '../../store/selectors';
import { getProjectIdFromUri } from '../../helpers/project-helpers';
import { routes } from '../../config';
import { fetchProject } from '../../store/actions/project.actions';

const validationSchema = yup.object({
  email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
  firstName: yup.string('Enter your first name').required('First name is required'),
  lastName: yup.string('Enter your last name').required('Last name is required'),
  tAndC: yup.boolean().oneOf([true], 'You must agree to the terms and conditions'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

const styles = {
  error: {
    color: '#d32f2f',
    fontSize: 'small',
  },
};

const theme = createTheme({});

function Signup({ signup, resetLocationTo, apiError, clearAPIErrors }) {
  const dispatch = useDispatch();
  const history = useHistory();

  // Determine if we're still fetching the users project
  const projectIsLoading = useSelector(Selectors.isProjectLoading);

  // Get the project from the store
  const project = useSelector(Selectors.getProject);

  useEffect(() => {
    dispatch(fetchProject(getProjectIdFromUri()));
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      tAndC: false,
    },
    validationSchema,
    onSubmit: (values) => {
      signup({ projectId: project?.projectId, ...values });
    },
  });

  const handleSigninLink = () => {
    resetLocationTo(routes.SIGNIN);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    clearAPIErrors();
    formik.handleSubmit();
  };

  // TODO: Change this to something peeeerty
  if (projectIsLoading) {
    return 'Loading....';
  }

  console.log('Project: ', project);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleFormSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox name="tAndC" color="primary" value={formik.values.tAndC} onChange={formik.handleChange} />
                  }
                  label="I agree to the terms and conditions."
                />
                <div>
                  {formik.touched.tAndC && formik.errors.tAndC && (
                    <span style={styles.error}>{formik.errors.tAndC}</span>
                  )}
                </div>
              </Grid>
            </Grid>
            <div>{apiError && <span style={styles.error}>{apiError}</span>}</div>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="" variant="body2" onClick={handleSigninLink}>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => ({ apiError: Selectors.getSignupError(state) });

const mapDispatchToProps = (dispatch) => ({
  signup: (values) => dispatch(signupUser(values)),
  resetLocationTo: (route) => dispatch(replace(route)),
  clearAPIErrors: () => dispatch(clearSignupFailure()),
});

/**
 * Note: This component is intentionally using `connect` to simplify integration with Formik.
 */
export default connect(mapStateToProps, mapDispatchToProps)(Signup);
