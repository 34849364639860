import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { appVersion } from '../config';

const Copyright = (props) => (
  <Typography variant="body2" color="text.secondary" align="center" {...props}>
    {'Copyright © '}
    <Link color="inherit" href="https://encodely.io/">
      Encodely
    </Link>{' '}
    {new Date().getFullYear()}. v{appVersion}
  </Typography>
);

export default Copyright;
