import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { connect, useDispatch, useSelector } from 'react-redux';
import { replace } from 'connected-react-router';
import { useHistory } from 'react-router-dom';
import Copyright from '../../components/Copyright';
import { requestPasswordReset as requestPasswordResetAction } from '../../store/actions/user.actions';
import * as Selectors from '../../store/selectors';
import { routes } from '../../config';
import { initialise } from '../../store/actions/project.actions';

const validationSchema = yup.object({
  email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
});

const theme = createTheme();

const styles = {
  errorText: { color: 'red', fontSize: 'small' },
};

function ResetPassword({ resetPassword, resetLocationTo }) {
  const dispatch = useDispatch();
  const history = useHistory();

  // Determine if we're still fetching the users project
  const projectIsLoading = useSelector(Selectors.isProjectLoading);

  const isLoading = useSelector(Selectors.getUserPasswordResetCodeIsLoading);

  // Get the project from the store
  const project = useSelector(Selectors.getProject);

  const apiError = useSelector(Selectors.getUserPasswordResetCodeError);

  useEffect(() => {
    dispatch(initialise({ queryParams: history.location.search }));
  }, [dispatch, history.location.search]);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: (values) => resetPassword(values),
  });

  const handleSignupLink = () => {
    resetLocationTo(routes.SIGNUP);
  };

  // TODO: Change this to something peeeerty
  if (projectIsLoading) {
    return 'Loading....';
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset your Password
          </Typography>
          <Typography component="p" variant="body2" sx={{ mt: 2 }} textAlign="center">
            Enter your email address below to receive instructions on how to reset your password.
          </Typography>
          <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1, minWidth: 350 }} maxWidth="xs">
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              autoFocus
            />
            <Typography style={styles.errorText}>{apiError}</Typography>
            <LoadingButton loading={isLoading} fullWidth type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              Continue
            </LoadingButton>
            <Grid container justifyContent="center">
              <Grid item>
                <Link href="" variant="body2" onClick={handleSignupLink}>
                  Don't have an account? Sign Up
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (values) => dispatch(requestPasswordResetAction(values)),
  resetLocationTo: (route) => dispatch(replace(route)),
});

/**
 * Note: This component is intentionally using `connect` to simplify integration with Formik. tsk tsk
 */
export default connect(null, mapDispatchToProps)(ResetPassword);
