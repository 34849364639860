import { toast } from 'react-toastify';
import axios from 'axios';
import { push } from 'connected-react-router';
import UserService from '../../services/user.service';
import {
  loginFailure,
  signupFailure,
  clearLoginFailure,
  clearSignupFailure,
  userPasswordResetCodeStart,
  userPasswordResetCodeSuccess,
  userPasswordResetCodeFailed,
  fetchUserFailure,
  fetchUserStart,
  setUserSuccess,
  logoutSuccess,
  resendUserVerificationCodeSuccess,
  resendUserVerificationCodeStart,
  resendUserVerificationCodeFailed,
  userVerificationFailed,
  userVerificationStart,
  setError,
} from '../slices/user.slice';
import { apiURL, routes } from '../../config';
import { translateError } from '../../helpers/error-helpers';
import { getURIParams } from '../selectors/index';

const parseError = (error) => {
  const { message: exceptionError } = error; // Normal Exception
  const { response: { status = null } = {} } = error; // Validation Error
  const { response: { data: { validation: { query: { message: queryValidationError } = {} } = {} } = {} } = {} } =
    error; // Validation Error
  const { response: { data: { validation: { body: { message: validationError } = {} } = {} } = {} } = {} } = error; // Validation Error
  const { response: { data: { error: { code, message: apiError } = {} } = {} } = {} } = error; // Business rule Error
  return { status, code, message: queryValidationError || validationError || apiError || exceptionError };
};

export const fetchUser = () => async (dispatch) => {
  try {
    dispatch(fetchUserStart());

    const { data: { redirect } = {} } = await UserService.login();

    window.location = redirect;
  } catch (error) {
    dispatch(fetchUserFailure(error));
    toast.error('Looks like there was an issue logging in. Please try again or contact support.', {
      autoClose: false,
    });
  }
};

export const setUser = (user) => async (dispatch) => {
  try {
    dispatch(setUserSuccess(user));
  } catch (error) {
    toast.error('Looks like there was an issue logging in. Please try again or contact support.', {
      autoClose: false,
    });
  }
};

export const signupUser =
  ({ email, password, firstName, lastName, tAndC, projectId }) =>
  async (dispatch) => {
    try {
      dispatch(clearSignupFailure());
      const response = await axios.post(`${apiURL}/auth/register`, {
        email,
        password,
        firstName,
        lastName,
        tAndC,
        projectId,
      });
      const { data: user } = response;

      dispatch(setUserSuccess(user));

      // Navigate to the verification screen
      if (!user.isVerified) {
        dispatch(push(routes.VERIFY));
      }
    } catch (error) {
      const { status, message } = parseError(error);
      dispatch(signupFailure(translateError(message, status)));
      console.log('Failed to register user:', error);
    }
  };

export const loginUser =
  ({ username, password }) =>
  async (dispatch, getState) => {
    try {
      dispatch(clearLoginFailure());

      const state = getState();
      dispatch(setError(null));

      const {
        redirect_uri: redirectURI,
        code_challenge: codeChallenge,
        code_challenge_method: codeChallengeMethod,
      } = getURIParams(state);

      const response = await UserService.signInWithPassword({
        username,
        password,
        redirectURI,
        codeChallenge,
        codeChallengeMethod,
      });
      const {
        data: { identityId, code, isMFAEnrolled },
      } = response;

      // dispatch(setUserSuccess(user));

      // Redirect to the users defined platform
      window.location.href = `${redirectURI}?code=${code}`;
    } catch (error) {
      const { status, code, message } = parseError(error);

      // TODO: Don't use strings here. Add to config
      if (code === 'USER_UNVERIFIED') {
        dispatch(setUserSuccess({ email: username }));
        dispatch(push(routes.VERIFY));
      } else {
        dispatch(loginFailure({ status, code, message: translateError(message, status) }));
      }
    }
  };

export const logoutUser = () => async (dispatch) => {
  try {
    const { data: { redirect } = {} } = await UserService.logout();

    if (redirect) {
      dispatch(logoutSuccess(redirect));
    }
  } catch (error) {
    toast.error('Looks like there was an issue logging out. Please try again or contact support.', {
      autoClose: false,
    });
  }
};

export const loginWithProvider =
  ({ provider }) =>
  async (dispatch, getState) => {
    try {
      dispatch(clearLoginFailure());

      const reduxState = getState();
      dispatch(setError(null));

      const {
        redirect_uri: redirectURI,
        code_challenge: codeChallenge,
        code_challenge_method: codeChallengeMethod,
        state,
      } = getURIParams(reduxState);

      await UserService.signInWithProvider({
        provider,
        state,
        redirectURI,
        codeChallenge,
        codeChallengeMethod,
      });
      // const {
      //   data: { identityId, code, isMFAEnrolled },
      // } = response;

      // dispatch(setUserSuccess(user));

      // Redirect to the users defined platform
      // window.location.href = `${redirectURI}?code=${code}`;
    } catch (error) {
      const { status, code, message } = parseError(error);

      // TODO: Don't use strings here. Add to config
      // if (code === 'USER_UNVERIFIED') {
      // dispatch(setUserSuccess({ email: username }));
      // dispatch(push(routes.VERIFY));
      // } else {
      dispatch(loginFailure({ status, code, message: translateError(message, status) }));
      // }
    }
  };

export const verifyUser =
  ({ email, verificationCode }) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(userVerificationStart());

      const response = await UserService.verifyUser(email, verificationCode);
      const { data: user } = response;

      dispatch(setUserSuccess(user));

      if (user.identity.isVerified) {
        const { redirect_uri: redirectURI } = getURIParams(state);
        // Redirect to the users defined platform
        window.location = redirectURI;
      }
    } catch (error) {
      const { status, message, code } = parseError(error);
      dispatch(userVerificationFailed({ status, code, message: translateError(message, status) }));
      console.log('Failed to request verification code:', error);
    }
  };

export const resendVerificationCode =
  ({ email }) =>
  async (dispatch) => {
    try {
      dispatch(resendUserVerificationCodeStart());

      await UserService.resendVerificationCode(email);

      dispatch(resendUserVerificationCodeSuccess());
    } catch (error) {
      const { status, message, code } = parseError(error);
      dispatch(resendUserVerificationCodeFailed({ status, code, message: translateError(message, status) }));
      console.log('Failed to request verification code:', error);
    }
  };

export const changePassword =
  ({ currentPassword, newPassword, confirmPassword, code }) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(setError(null));

      const { redirect_uri: redirectURI } = getURIParams(state);

      // Unauthed route and requires a reset code which is sent to the users email so they prove who they are
      await UserService.changePasswordWithCode(currentPassword, newPassword, confirmPassword, code);

      // We don't really need to be concerned about this redirect URI as it does not contain anything sensitive.
      // As a future update we should at least verify this is redirecting to a registered origin
      const returnURL = new URL(redirectURI);

      // Redirect to the users defined platform
      window.location.href = returnURL;
    } catch (error) {
      const { message } = parseError(error);
      dispatch(setError(message));
      console.log('Failed to change users password:', error);
    }
  };

export const requestPasswordReset =
  ({ email }, projectId, redirectURL) =>
  async (dispatch) => {
    try {
      dispatch(userPasswordResetCodeStart());

      await UserService.requestPasswordReset(email, projectId, redirectURL);

      dispatch(userPasswordResetCodeSuccess());
    } catch (error) {
      const { status, message } = parseError(error);

      dispatch(userPasswordResetCodeFailed(translateError(message, status)));
      console.log('Failed to request verification code:', error);
    }
  };

export const resetPassword =
  ({ newPassword, confirmPassword, code }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setError(null));
      const state = getState();

      await UserService.resetPassword({
        newPassword,
        confirmPassword,
        code,
      });

      const { redirect_uri: redirectURI } = getURIParams(state);

      const returnURL = new URL(redirectURI);

      // Redirect to the users defined platform
      window.location.href = returnURL;
    } catch (error) {
      const { message } = parseError(error);
      dispatch(setError(message));
      console.log('Failed to reset users password:', error);
    }
  };
